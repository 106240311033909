// src/languages/en-GB.js
const enGB = {
  AUTH: {
    SIGN_IN: "Sign in",
    SIGN_UP: "Sign up",
    JOIN_TODAY: "Join us today",
    EMAIL_PASSWORD: "Enter your email and password to register",
    AGREE: "I agree to the ",
    TERMS: "Terms and Conditions",
    LOGGING_IN: "You're being logged in...",
    REMEMBER_ME: "Remember Me",
    HAVE_ACCOUNT: "Already have an account?",
    DONT_HAVE_ACCOUNT: "Don't have an account?",
    // Add more as needed
  },
  GENERAL: {
    TERMS_CONDITIONS: "Terms and Conditions",
  },
};

export default enGB;
