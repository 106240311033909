import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Import the language file
import enGB from "../../../languages/en-GB";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

function EmailVerification() {
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    // This will check the actionCode from the URL sent by Firebase to handle the verification
    const queryParams = new URLSearchParams(window.location.search);
    const mode = queryParams.get("mode");
    const actionCode = queryParams.get("oobCode");

    if (mode === "verifyEmail" && actionCode) {
      // Process the email verification
      window.firebase
        .auth()
        .applyActionCode(actionCode)
        .then(() => {
          setIsVerified(true);
        })
        .catch((error) => {
          console.error("Error verifying email:", error);
        });
    }
  }, []);

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {enGB.AUTH.EMAIL_VERIFIED_TITLE}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {isVerified ? (
            <>
              <MDTypography variant="h6" color="info" textAlign="center">
                {enGB.AUTH.EMAIL_VERIFIED_SUCCESS}
              </MDTypography>
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  component={Link}
                  to="/authentication/sign-in"
                >
                  {enGB.AUTH.SIGN_IN}
                </MDButton>
              </MDBox>
            </>
          ) : (
            <MDTypography variant="h6" color="error" textAlign="center">
              {enGB.AUTH.EMAIL_VERIFIED_FAILED}
            </MDTypography>
          )}
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default EmailVerification;
