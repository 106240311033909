import { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Firebase imports
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../firebase"; // Import auth from your firebase.js file

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Import the language file
import enGB from "../../../languages/en-GB";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

function Basic() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const navigate = useNavigate();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSignIn = async (e) => {
    e.preventDefault();
    setIsAuthenticating(true); // Start the authentication process
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // Redirect to dashboard after successful authentication
      navigate("/dashboard");
    } catch (error) {
      setIsAuthenticating(false); // Stop authentication process if there's an error
      setError(error.message);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {enGB.AUTH.SIGN_IN}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {isAuthenticating ? (
            // Show this message when the user is being authenticated
            <MDTypography variant="h6" color="info" textAlign="center">
              {enGB.AUTH.LOGGING_IN}
            </MDTypography>
          ) : (
            // Render the form when the user is not authenticated yet
            <MDBox component="form" role="form" onSubmit={handleSignIn}>
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  fullWidth
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </MDBox>
              {error && (
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="error">
                    {error}
                  </MDTypography>
                </MDBox>
              )}
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;{enGB.AUTH.REMEMBER_ME}
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton type="submit" variant="gradient" color="info" fullWidth>
                  {enGB.AUTH.SIGN_IN}
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  {enGB.AUTH.DONT_HAVE_ACCOUNT}{" "}
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-up"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    {enGB.AUTH.SIGN_UP}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          )}
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
